<template>
<!-- put the 100th vertical pixel at the top of the window -->

  <div>
    <status-display
      :statusReqUrl="reqUrl"
      :processRecordUrl="processRecordUrl"
      :clusterPrimaryKey="clusterPrimaryKey"
    ></status-display>
  </div>
</template>

<script>
import statusDisplay from "./DisplayCurStatus.vue"
const PROCESS_ACTIVE = require("../../assets/constant").PROCESS_ACTIVE;
export default {
  components:{
    statusDisplay,
  },
  created(){
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.CONSTRUCT)
    this.$store.commit("setClusterPrimaryKey",this.clusterPrimaryKey)
    sessionStorage.setItem("clusterPrimaryKey",this.clusterPrimaryKey)
  },
  data() {
    return {
      reqUrl: '/api/driver/getProcessStatusByClusterId',
      processRecordUrl: '/api/driver/getApplicationStatusByClusterId',
      clusterPrimaryKey: !this.$route.params.clusterPrimaryKey?sessionStorage.getItem("clusterPrimaryKey"):this.$route.params.clusterPrimaryKey,
    }
  },
  beforeUnmount() {
    // sessionStorage.removeItem("clusterPrimaryKey")
    // console.log("已清除");
  },

  beforeRouteLeave() {
    // this.$store.commit('setStatusPageInterval', null);
  },
}
</script>

<style lang="less">
</style>