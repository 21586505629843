<template>
  <div class="buildingStatus" ref="test">
    <!-- <div>{{ count }}</div> -->
    <el-card shadow="hover">
      <el-button type="primary" @click="this.$router.push('/')">返回</el-button>
      <el-row :gutter="0">
        <el-col :span="12">
          <div class="status">
            <el-progress
              :type="circleType"
              :status="status"
              :percentage="currentRate"
              v-model="rate"
              :color="statusDes.color"
              id="createProgress"
            >
              <div :style="{ color: statusDes.color }">
                <span class="percentage-value">{{ currentRate }}%</span>
                <span class="percentage-label">{{
                  allTrue() ? statusDes.sentence : "创建失败"
                }}</span>
              </div>
            </el-progress>
          </div>

          <!-- 100%后把url及账密提供到该位置覆盖默认文案 -->
          <el-card shadow="hover" class="enter-channel">
            <div>
              <el-button
                type="primary"
                plain
                :disabled="allTrue() ? false : true"
                @click="urlFLag = !urlFLag"
                >授权信息</el-button
              >
              <div class="enter-channel-info" v-show="!!urlFLag">
                <el-descriptions
                  class="el-descriptions"
                  title="服务渠道信息"
                  column="1"
                  direction="horizontal"
                >
                  <el-descriptions-item>
                    <span class="el-descriptions-item">
                      用户名:
                      {{ userServiceInfo.username }}
                    </span>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <span class="el-descriptions-item">
                      MetaSoul Studio:
                      <el-popover
                        placement="right-start"
                        title="Studio地址"
                        :width="200"
                        trigger="hover"
                        :content="userServiceInfo.serviceUrl"
                      >
                        <template #reference>
                                                  <el-button
                          type="success"
                          plain
                          @click="enterIDE(userServiceInfo.serviceUrl)"
                          >点击进入IDE</el-button
                        >
                        </template>
                      </el-popover>
                    </span>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <!-- 创建状态进度文案 -->

          <div class="table-data">
            <el-scrollbar
              height="400px"
              ref="scrollbarRef"
              always
              @scroll="scroll"
            >
              <el-timeline>
                <!-- 通过 ref="timeline" 获取高度 -->
                <el-timeline-item
                  v-for="(activity, index) in tableData"
                  :key="index"
                  :icon="activity.icon"
                  :type="activity.type"
                  :color="activity.color"
                  :size="activity.size"
                  :hollow="activity.hollow"
                  :timestamp="activity.timestamp"
                  ref="timeline"
                >
                  <el-card
                    v-if="
                      activity.content === '集群应用部署' &&
                      appTableData.length > 0
                    "
                  >
                    <div :style="{ color: activity.color }">
                      {{ activity.content }}
                    </div>

                    <div
                      class="progress"
                      v-for="index in Object.keys(appTableData)"
                      :key="index"
                      :style="{ color: appTableData[index].color }"
                    >
                      {{ appTableData[index].content }}
                      <!-- 没有进入集群应用部署阶段前percentage 应设置为Null或0 进入后设置为100 可实现动画效果-->
                      <el-progress
                        :status="appTableData[index].status"
                        :indeterminate="appStillInstall(appTableData[index])"
                        :duration="5"
                        :percentage="
                          appTableData[index].status === 'notBegined'
                            ? null
                            : 100
                        "
                        :stroke-width="2"
                      >
                        {{ appTableData[index].icon }}
                        {{ appTableData[index].timestamp }}
                      </el-progress>
                      <el-button
                        type="primary"
                        :icon="Edit"
                        :disabled="decideAppEnableEdit()"
                        @click="routeToEdit(appTableData[index].content)"
                        >修改配置</el-button
                      >
                    </div>
                  </el-card>
                  <el-card v-else>
                    <div :style="{ color: activity.color }">
                      {{ activity.content }}
                    </div>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- <el-button type="primary" @click="test">test</el-button> -->
  </div>
</template>

<script>
const axios = require("axios");
const moment = require("moment");
const createStagesInfo = require("../../assets/constant").CREATE_STAGE_INFO;

export default {
  data() {
    return {
      firstTimeEnter: true,
      // count: 0,
      urlFLag: false,
      stageTimes: [],
      stageColors: [],
      stageSuccess: [],
      currentRate: 0,
      currentProcessStep: "CREATE_START", // little trick here; mainly for watch
      circleType: "circle",
      status: "",
      statusDes: {
        sentence: "准备创建",
        color: "#33CCFF",
      },
      appTableData: {},
      userServiceInfo: {
        serviceUrl: "部署中",
        username: "部署中",
      },
      statusReq: "",
    };
  },
  computed: {
    APPCONFIGBUTTONENABLE: "修改配置",

    tableData() {
      let tb = []; // tableData
      for (let i = 0; i < this.$options.stagesInfo.length; i++) {
        let tbItem = {};
        // 循环获取 整个流程的静态渲染文件
        tbItem["content"] = this.$options.stagesInfo[i]["stageDescription"];
        tbItem["timestamp"] = this.stageTimes[i].toString().slice(0, -9); //接口获取维持本数组渲染
        tbItem["color"] = this.stageColors[i];
        tb.push(tbItem);
      }
      return tb;
    },
    appTableData() {
      let atb = []; // appTableData
      Object.keys(this.appTableData).forEach((key) => {
        let atbItem = {};
        atbItem["content"] = key.toString();
        // 处理时间
        if (this.appTableData[key].update_time.toString().length === 13)
          this.appTableData[key].update_time =
            this.appTableData[key].update_time / 1000;
        atbItem["timestamp"] = this.appTableData[key].update_time
          ? moment
              .unix(Math.round(this.appTableData[key].update_time))
              .format("MMMM Do YYYY, h:mm:ss a")
          : "";
        // 获取应用配置状态和颜色
        [atbItem["status"], atbItem["color"]] = this.statusTrans(
          this.appTableData[key].status
        );
        //  = this.stageColors[i];
        atb.push(atbItem);
      });
      return atb;
    },
  },
  props: ["statusReqUrl", "clusterPrimaryKey", "processRecordUrl"],
  watch: {
    currentProcessStep: function (newProcessStep) {
      // 检测步骤中，是否有失败的
      const success = this.allTrue();
      if (!success) {
        // stageSuccess 中存在失败步骤
        this.statusDes.sentence = "创建失败";
        this.statusDes.color = "#f56c6c";
      } else if (newProcessStep === "CLUSTER_COMPLETE") {
        // stageSuccess 不存在失败步骤 且 传来的字符串为 CLUSTER_COMPLETE
        this.statusDes.sentence = "创建成功";
        this.statusDes.color = "#0bbd87";
      } else {
        // 否则仍处于 创建中
        this.statusDes.sentence = "创建中";
      }
      // getIndex 获取新步骤在第几位
      let ind = this.getIndex(newProcessStep);
      if (ind !== -1) {
        // let currentTime = this.stageTimes[ind];
        // 新步骤在静态文件中存在，检测该步骤成功与否，替换颜色
        if (this.stageSuccess[ind]) {
          this.stageColors[ind] = "#0bbd87"; // 绿色
        } else {
          this.stageColors[ind] = "#f56c6c"; // 红色
        }
        // 保存当前步骤值
        const temnum = ind;
        // 计算步骤高度，并进行滚动
        let height = 0;
        for (let i = 0; i < temnum; i++) {
          height += this.$refs.timeline[i].$el.offsetHeight;
        }
        this.$refs.scrollbarRef.setScrollTop(height);
        // 把之前步骤颜色改为绿色
        while (ind-- >= 0) {
          this.stageColors[ind] = "#0bbd87"; // 绿色
        }
      }
    },
  },
  mounted() {
    //配置定时器
    this.statusReq = setInterval(this.getStatusApi, 10000);
  },

  created() {
    //初始化步骤流程文案
    this.$options.stagesInfo = createStagesInfo;
    // 进程记录
    this.$options.processRecords = new Array();
    const len = this.$options.stagesInfo.length;
    // 步骤时间
    this.stageTimes = new Array(len).fill("-");
    // 步骤颜色初始化为 灰色
    this.stageColors = new Array(len).fill("#e4e7ed"); // 灰色
    // 步骤状态初始均为 成功
    this.stageSuccess = new Array(len).fill(true);

    //首次访问后端api加载状态
    this.getStatusApi();
  },

  unmounted() {
    // 销毁定时器
    clearInterval(this.statusReq);
  },
  methods: {
    decideAppEnableEdit() {
      // console.log(this.currentRate);
      // console.log(this.statusDes.sentence);
      const success = this.allTrue();
      if (success && this.currentRate !== 100) {
        return true;
      }
      return false;
    },
    getStatusApi() {
      console.log(
        this.currentRate !== 100 ||
          (this.currentRate === 100 &&
            Object.keys(this.appTableData).length > 0 &&
            Object.keys(this.appTableData).some(
              (appKey) => this.appTableData[appKey].status !== "deployed"
            ))
      );
      if (
        this.currentRate !== 100 ||
        (this.currentRate === 100 &&
          Object.keys(this.appTableData).length > 0 &&
          Object.keys(this.appTableData).some(
            (appKey) => this.appTableData[appKey].status !== "deployed"
          ))
      ) {
        //如果此处为100，说明是created的时候第一次获取就是100，已经是创建完毕状态，此方法会从后端获取studio的user和url展示给用户，同样定时去获取并更新
        // 不为 100，即没创建完成 || 为100，且应用中存在未部署的un deployed) 都需要调用接口
        // 获取集群创建进度
        axios
          .get(this.statusReqUrl, {
            params: {
              clusterPrimaryKey: this.clusterPrimaryKey,
            },
          })
          .then((response) => {
            if (response.data.data.length) {
              // 获取最新 步骤
              let currentProcess = this.sortRecordAndGetCurrentProcess(
                response.data.data
              );
              // 获取当前进度 0-100%
              this.currentRate = currentProcess.processRate;
              // 当前进度步骤
              this.currentProcessStep = currentProcess.processStep;
              // 进度步骤的状态
              this.statusDes.sentence = currentProcess.processDescribe;
              // 获取当前进度的顺序值
              const ind = this.getIndex(this.currentProcessStep);
              // 更新进度情况
              this.stageSuccess[ind] = currentProcess.success;
              //利用返回的创建记录构建时间戳数组逻辑以及当前创建状态
              this.$options.processRecords = response.data.data;
              this.$options.processRecords.forEach((processItem, i) => {
                this.stageTimes[i] = moment(
                  processItem.processTime,
                  moment.ISO_8601
                );
                // this.stageTimes[i] = moment
                //   .unix(
                //     parseInt(processItem.processTime.toString().slice(0, -3))
                //   )
                //   .format("MMMM Do YYYY, h:mm:ss a");
              });
            }
          })
          .then(() => {
            // 获取应用配置进度
            axios
              .get(this.processRecordUrl, {
                params: {
                  clusterPrimaryKey: this.clusterPrimaryKey,
                },
              })
              .then((res) => {
                this.appTableData = res.data.data;
                let promiseA = new Promise((resolve, reject) => {
                  resolve();
                });
                promiseA.then(() => {
                  let ind = this.getIndex(this.currentProcessStep);
                  if (ind !== -1) {
                    // let currentTime = this.stageTimes[ind];
                    if (this.stageSuccess[ind]) {
                      this.stageColors[ind] = "#0bbd87"; // 绿色
                    } else {
                      this.stageColors[ind] = "#f56c6c"; // 红色
                    }
                    const temnum = ind;
                    let height = 0;
                    for (let i = 0; i < temnum; i++) {
                      height += this.$refs.timeline[i].$el.offsetHeight;
                    }
                    this.$refs.scrollbarRef.setScrollTop(height);
                  }
                });
              });
          })
          .then(() => {
            if (this.currentRate === 100) {
              //判断第一次进来就100%的情况 访问后端接口获取并更新studio的user和url
              axios
                .get("/api/driver/getStudio", {
                  params: { clusterPrimaryKey: this.clusterPrimaryKey },
                })
                .then((res) => {
                  this.userServiceInfo.serviceUrl = res.data.data.url;
                  this.userServiceInfo.username = res.data.data.user;
                })
                .catch((err) => {
                  this.userServiceInfo.serviceUrl = "服务器异常";
                  this.userServiceInfo.username = "服务器异常";
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //创建完毕后 定时访问后端接口获取并更新studio的user和url
        axios
          .get("/api/driver/getStudio", {
            params: { clusterPrimaryKey: this.clusterPrimaryKey },
          })
          .then((res) => {
            this.userServiceInfo.serviceUrl = res.data.data.url;
            this.userServiceInfo.username = res.data.data.user;
          })
          .catch((err) => {
            this.userServiceInfo.serviceUrl = "服务器异常";
            this.userServiceInfo.username = "服务器异常";
          });
      }
    },
    // 跳转对应应用配置路由
    routeToEdit(appActivityName) {
      this.$router.push({
        name: "AppConfigEdit",
        params: {
          clusterPrimaryKey: this.clusterPrimaryKey,
          appActivityName: appActivityName,
        },
      });
    },
    // 格式化日期
    format(date) {
      const padding = function (num) {
        if (num <= 9) {
          return "0" + num;
        }
        return num;
      };
      return `${date.getFullYear()}-${padding(date.getMonth() + 1)}-${padding(
        date.getDate()
      )} ${padding(date.getHours())}:${padding(date.getMinutes())}`;
    },
    // 排序获取最新进度
    sortRecordAndGetCurrentProcess(processRecord) {
      processRecord.sort(
        (processBefore, processAfter) =>
          processBefore.processRate - processAfter.processRate
      );
      return processRecord[processRecord.length - 1];
    },
    getIndex(processStep) {
      let ind = -1;
      for (let i = 0; i < this.$options.stagesInfo.length; i++) {
        if (this.$options.stagesInfo[i]["processStep"] === processStep) {
          ind = i;
          break;
        }
      }
      return ind;
    },

    allTrue() {
      return this.stageSuccess.every((item) => {
        return item;
      });
    },

    statusTrans(status) {
      if (
        status === "uninstalling" ||
        status === "pending-install" ||
        status === "pending-upgrade" ||
        status === "pending-rollback" ||
        status === "holding-update"
      ) {
        return "format";
      } else if (status === "deployed") {
        return ["success", "#0bbd87"];
      } else if (
        status === "unknown" ||
        status === "uninstalled" ||
        status === "superseded" ||
        status === "failed"
      ) {
        return ["exception", "#f56c6c"];
      } else {
        return ["notBegined", "#e4e7ed"];
      }
    },
    appStillInstall(appActivity) {
      return (
        appActivity.status !== "success" && appActivity.status !== "exception"
      );
    },
    enterIDE(url) {
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
.status {
  margin: 5%;
  z-index: 99; /*浮动在最上层 */
  /* margin-left: 37%; */
}
.table-data {
  margin: 5%;
  z-index: 99; /*浮动在最上层 */
}

.percentage-value {
  display: block;
  margin-top: 10px;
  font-size: 28px;
}

.percentage-label {
  display: block;
  margin-top: 10px;
  font-size: 12px;
}
.progress {
  margin-bottom: 15px;
}
.enter-channel {
  margin-top: 10%;
  padding: 10px;
}
#createProgress {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-descriptions-item {
  font-size: 17px;
}
</style>>